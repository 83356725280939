import { ApolloClient, InMemoryCache, from, HttpLink, ApolloClientOptions } from '@apollo/client';
import { DefaultOptions } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { useMemo } from 'react';

import { AppPageProps } from '@/cutils/page-context/server-side-props';
import { logger } from '@/utils/logging/logger';

import fragmentMatcherData from './fragmentMatcherData';
import { typePolicies } from './type-policies';

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<unknown>;

const defaultOptionsClient: DefaultOptions = {
	query: { fetchPolicy: 'cache-first', errorPolicy: 'all' },
	watchQuery: { fetchPolicy: 'cache-first', errorPolicy: 'all' },
};

const location = typeof window === 'undefined' ? 'server' : 'client';

interface ClientOptions {
	uri: string;
	branch: string;
	defaultOptions: DefaultOptions;
	accessToken?: string;
}

const createHeaderMiddleware = ({ branch, accessToken }: ClientOptions) =>
	setContext(() => {
		const uaHeader = `br24-web (${location}-side;${branch})`;

		const contextOptions: { headers: Record<string, string | Headers> } = {
			headers: {
				'x-client': uaHeader,
				'User-Agent': uaHeader,
			},
		};

		if (accessToken) {
			contextOptions.headers['authorization'] = `Bearer ${accessToken}`;
		}

		return contextOptions;
	});

const customFetch: WindowOrWorkerGlobalScope['fetch'] = async (uri, options) => {
	return fetch(uri, options).then(async (response) => {
		if (response.status < 200 || response.status >= 400) {
			/* this helps us to better identify what was going wrong (e.g. url) */
			let responseText = '';
			try {
				responseText = await response.text();
			} catch {
				responseText = 'could not parse response text';
			}
			logger.error('GRAPHQL: FETCH_ERROR', {
				uri,
				options,
				response: {
					text: responseText,
					status: response.status,
					statusText: response.statusText,
					headers: response.headers,
					redirected: response.redirected,
					url: response.url,
					type: response.type,
				},
			});
			return Promise.reject(response);
		}
		return response;
	});
};

const createLink = (options: ClientOptions) => {
	return from([
		onError((err) => {
			const { graphQLErrors, networkError, response, operation } = err;

			if (graphQLErrors) {
				const errors = graphQLErrors.map(({ message, locations, path, extensions }) => ({ message, locations, path, extensions }));

				logger.error('Graphql semantic error', { errors, graphqlOperationName: operation.operationName });
			}

			if (networkError) {
				logger.error(`Graphql network error`, { networkError, response, graphqlOperationName: operation.operationName });
			}

			if (!graphQLErrors && !networkError) {
				logger.error(`Graphql error`, { error: err });
			}
		}),

		createHeaderMiddleware(options),
		createPersistedQueryLink({
			sha256,
			// DO NOT REMOVE THIS LINE! DO NOT REMOVE THIS LINE! DO NOT REMOVE THIS LINE! DO NOT REMOVE THIS LINE!
			//
			// Akamai documentation:
			//   Allow use of the POST HTTP request method. By default, GET, HEAD and OPTIONS are the only method
			//   honored, and others are denied with a 403. With this behavior enabled, POST requests pass to the
			//   origin, and do not cache.
			useGETForHashedQueries: true,
		}),
		new HttpLink({ uri: `${options.uri}/graphql`, fetch: customFetch }),
	]);
};

// After debugging for a couple of hours I learned:
// It is important that we create the `ApolloClient` & `InMemoryCache` every time we make a new request.
// This is why we do it inside the `withApollo` export. If we don't, the data gets stale on SSR because the cache is never
// updated as long as the server runs.

function createApolloClient(options: ClientOptions) {
	const finalOptions: ApolloClientOptions<unknown> = {
		connectToDevTools: options.branch !== 'master',
		ssrMode: typeof window === 'undefined',
		cache: new InMemoryCache({
			possibleTypes: fragmentMatcherData.possibleTypes,
			typePolicies,
		}),
		link: createLink(options),
		defaultOptions: options.defaultOptions,
	};

	if (options?.accessToken) {
		finalOptions.headers = { authorization: `Bearer ${options.accessToken}` };
	}

	return new ApolloClient(finalOptions);
}

export function initializeApollo(initialState = null, options: ClientOptions) {
	const _apolloClient = apolloClient ?? createApolloClient(options);

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here

	// reset the store if a page navigation occurs (merging caches between pages is very hard to achieve)
	if (initialState) {
		_apolloClient.restore(initialState);
	}

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') {
		return _apolloClient;
	}

	// Create the Apollo Client once in the client
	if (!apolloClient) {
		apolloClient = _apolloClient;
	}

	return apolloClient;
}

export function addApolloState(client: ApolloClient<unknown>, pageProps: any) {
	if (pageProps?.props) {
		pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
	}

	return pageProps;
}

export function useApollo(pageProps: AppPageProps) {
	// @ts-expect-error the apollo state is hidden from the type as it should only be used here and when serializing the state
	const state = pageProps[APOLLO_STATE_PROP_NAME];
	const store = useMemo(
		() =>
			initializeApollo(state, {
				uri: pageProps.environment.endpoints.ENDPOINT_GRAPHQL,
				branch: pageProps.environment.constants.BRANCH,
				defaultOptions: defaultOptionsClient,
			}),
		[state, pageProps.environment.endpoints.ENDPOINT_GRAPHQL, pageProps.environment.constants.BRANCH]
	);

	return store;
}
