export async function unregisterWorkerByScope(scope: string) {
	if ('serviceWorker' in navigator) {
		const sw = navigator.serviceWorker;
		const registrations = await sw.getRegistrations();

		registrations.forEach(async (registration) => {
			if (registration.scope.endsWith(scope)) {
				registration.unregister();
			}
		});
	}
}
