import { AbcTestType } from '@/cutils/context/AbcTestContext';
import { notNullOrUndefined } from '@/cutils/misc/notNullOrUndefined';
import { ServerSidePropsQuery } from '@/cutils/page-context/__generated__/server-side-props.generated';
import { FeatureFlags } from '@/types/featureFlags';
import { logger } from '@/utils/logging/logger';

function normalizeFeatureFlags(data?: ServerSidePropsQuery['featureFlags']): FeatureFlags {
	const featureFlagsArray = data?.nodes || [];
	const [featureFlags] = featureFlagsArray.filter(notNullOrUndefined).map(({ key, value }) => ({ [key]: JSON.parse(value) }));
	if (!featureFlags) {
		return {};
	}
	return featureFlags;
}

interface FeatureFlagsState {
	flags: FeatureFlags;
}

function pickTest(): AbcTestType {
	return ['A', 'B', 'C'][Math.floor((Math.random() * 10) % 3)] as AbcTestType;
}

export const useFeatureFlags = (data: ServerSidePropsQuery['featureFlags']): FeatureFlagsState => {
	let flags: FeatureFlags = {};

	if (!data) {
		logger.error(`No data returned from 'useFeatureFlags'.`);
	}

	if (data) {
		flags = normalizeFeatureFlags(data);

		flags.abcTest = flags.abcTest || flags.mvTest || { variant: pickTest() };

		if (typeof window !== 'undefined' && window.location.search && window.location.search === '?preview') {
			flags.abcTest.variant = 'B';
		}

		if (!/[ABC]/.test(flags.abcTest.variant)) {
			// final check (was in ContextProvider before)
			flags.abcTest.variant = 'B';
		}
	}

	return { flags };
};
