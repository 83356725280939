import { PropsWithChildren } from 'react';

import { ThemeImage } from '../base/ThemeImage';

import styles from './ErrorLayout.module.scss';
import ErrorImageDark from './error-dark.svg';
import ErrorImageLight from './error-light.svg';

const ErrorLayout = ({ children }: PropsWithChildren<{}>) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				<ThemeImage srcLight={ErrorImageLight} srcDark={ErrorImageDark} alt="Fehler Bild" />
			</div>
			{children}
		</div>
	);
};

export default ErrorLayout;
