import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

const scrollMap: Record<string, number> = {};

function getPathname(path: string) {
	let { pathname } = new URL(path, 'http://localhost');

	// remove trailing slash
	if (pathname !== '/' && pathname[pathname.length - 1] === '/') {
		pathname = pathname.substring(0, pathname.length - 1);
	}

	return pathname;
}

export function useMaintainScrollPosition() {
	const router = useRouter();

	const saveScrollPosition = useCallback(() => {
		const pathname = getPathname(router.asPath);
		scrollMap[pathname] = window.scrollY;
	}, [router.asPath]);

	const restoreScrollPosition = useCallback(() => {
		const path = router.asPath;
		// do not restore the scroll position if the route contains a hash so links to ids
		// work as expected
		if (!path.includes('#')) {
			const pathname = getPathname(path);
			const top = scrollMap[pathname];

			if (typeof top === 'number') {
				window.scrollTo({ top, behavior: 'auto' });
			}
		}
	}, [router.asPath]);

	useEffect(() => {
		router.events.on('routeChangeStart', saveScrollPosition);
		router.events.on('routeChangeComplete', restoreScrollPosition);

		return () => {
			router.events.off('routeChangeStart', saveScrollPosition);
			router.events.off('routeChangeComplete', restoreScrollPosition);
		};
	});
}
