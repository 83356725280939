import { MutableRefObject, useMemo } from 'react';

import { Link } from '@/components/Link/Link';
import { useBreakpoint } from '@/cutils/hooks/useBreakpoint';
import { NavigationList } from '@/cutils/page-context/navigation';
import { Breakpoint } from '@/types/enums';
import { IconSystem } from 'src/client/icons/IconSystem';

import styles from './HeaderNavigation.module.scss';
import { HighlightNavigation } from './HighlightNavigation';
import { Logo } from './Logo';
import { ScrollDirection, useVerticalScrollDirection } from './use-vertical-scroll-position';

type Props = {
	className?: string;
	menuOpen: boolean;
	navigationTitle: string;
	onMainMenuClick?: () => void;
	onBayernMenuClick?: () => void;
	menuButtonRef: MutableRefObject<HTMLButtonElement | undefined>;
	bayernButtonRef: MutableRefObject<HTMLButtonElement | undefined>;
	highlightNavigation: NavigationList;
};

export function HeaderNavigation({
	className,
	menuOpen,
	onMainMenuClick,
	onBayernMenuClick,
	menuButtonRef,
	bayernButtonRef,
	navigationTitle,
	highlightNavigation,
}: Props) {
	const isDesktop = useBreakpoint(Breakpoint.TabletLarge);
	const direction = useVerticalScrollDirection(20, isDesktop);

	// this will prevent the search link to rerender when scroll direction changes.
	const search = useMemo(() => {
		return (
			<Link href="/suche" className={`${styles.navigationButton} ${styles.search}`}>
				<span className={styles.buttonCaption}>Suche</span>
				<IconSystem className={styles.navigationIcon} icon="search" variant="filled" title={isDesktop ? undefined : 'Suche'} />
			</Link>
		);
	}, [isDesktop]);

	const isCondensed = direction === ScrollDirection.Down;

	const handleMenuButtonRef = (element: HTMLButtonElement) => {
		menuButtonRef.current = element;
	};

	return (
		<div
			className={`${styles.navigationContainer} ${className ?? ''} ${menuOpen ? styles.menuOpen : ''} ${isDesktop && isCondensed ? styles.condensed : ''}`}
		>
			<Logo className={styles.logo} />
			<HighlightNavigation
				className={styles.highlightNavigation}
				highlightNavigation={highlightNavigation}
				onBayernMenuClick={onBayernMenuClick}
				bayernButtonRef={bayernButtonRef}
			></HighlightNavigation>
			<div className={`heading4 ${styles.title}`}>{navigationTitle}</div>

			<div className={styles.navigationButtons}>
				{search}

				<button
					className={`heading4 ${styles.navigationButton}`}
					title={menuOpen ? 'Menü schließen' : 'Menü öffnen'}
					onClickCapture={() => onMainMenuClick?.()}
					aria-haspopup="menu"
					aria-expanded={menuOpen}
					ref={handleMenuButtonRef}
				>
					<span className={styles.buttonCaption}>{menuOpen ? 'schließen' : 'Menü'}</span>
					{menuOpen ? (
						<IconSystem icon="close" variant="filled" className={styles.navigationIcon} title={isDesktop ? undefined : 'schließen'} />
					) : (
						<IconSystem icon="menu" variant="filled" className={styles.navigationIcon} title={isDesktop ? undefined : 'Navigationsmenü'} />
					)}
				</button>
			</div>
		</div>
	);
}
