import ErrorLayout from './ErrorLayout';
import styles from './ErrorServerError.module.scss';

const ErrorServerError = () => {
	return (
		<ErrorLayout>
			<div className={`body1 ${styles.root}`}>
				<div className={`heading1 ${styles.titlePrimary}`}>Allgemeiner Fehler 500 / Interner Serverfehler</div>

				<p className={styles.text}>Es ist ein Fehler aufgetreten, unser Server kann Ihre Anfrage gerade nicht beantworten.</p>

				<p className={styles.text}>Versuchen Sie es bitte in Kürze noch einmal.</p>
			</div>
		</ErrorLayout>
	);
};

export default ErrorServerError;
