import { forwardRef, memo } from 'react';

import { useEnvironment } from '@/cutils/context/EnvironmentContext';
import { NavigationTree } from '@/cutils/page-context/navigation';
import { isNavigationLeafNode } from '@/cutils/page-context/navigation.utils';
import { getInertValue } from '@/cutils/react/inert-attribute';
import { IconSystem } from 'src/client/icons/IconSystem';

import { Tab } from '../Tabs/Tab';
import { Tabs } from '../Tabs/Tabs';
import { ThemeSelectorMenu } from '../ThemeSelector/ThemeSelectorMenu';

import styles from './MainNavigation.module.scss';
import { NavigationList } from './NavigationList/NavigationList';
import { NavigationListCustomItem, NavigationListLinkItem, NavigationListNodeItem } from './NavigationList/NavigationListItem';

type Props = {
	menuOpen: boolean;
	navigation: {
		rubriken: NavigationTree;
		bayern: NavigationTree;
	};
	className?: string;
};

function renderNavigationTree(tree: NavigationTree, depth: number) {
	return tree.map((navigationNode) => {
		if (isNavigationLeafNode(navigationNode)) {
			return (
				<NavigationListLinkItem
					depth={depth}
					href={navigationNode.link}
					key={navigationNode.id}
					target={navigationNode.isExternal ? '_blank' : undefined}
				>
					{navigationNode.title}
				</NavigationListLinkItem>
			);
		} else {
			return (
				<NavigationListNodeItem depth={depth} title={navigationNode.title} key={navigationNode.id}>
					{renderNavigationTree(navigationNode.children, depth + 1)}
				</NavigationListNodeItem>
			);
		}
	});
}

export const MainNavigation = memo(
	forwardRef<HTMLElement, Props>(({ className, navigation, menuOpen }, ref) => {
		const environment = useEnvironment();
		const radioUrl = `${environment.endpoints.ENDPOINT_RADIO}/br24?autoplay`;

		const rubrikenNavigationItems = renderNavigationTree(navigation.rubriken, 0);
		const bayernNavigationItems = renderNavigationTree(navigation.bayern, 0);

		return (
			<nav
				ref={ref}
				className={`${styles.navigation} ${menuOpen ? styles.open : ''} ${className ?? ''}`}
				aria-label="Hauptnavigation"
				hidden={!menuOpen}
				inert={getInertValue(!menuOpen)}
				// tracking attributes
				data-t-element="top_navigation"
				data-t-type="Hauptnavigation"
			>
				<Tabs className={styles.navigationTabs}>
					<Tab label="Rubriken" icon={<IconSystem icon="board" variant="filled" />}>
						<NavigationList>
							<NavigationListLinkItem depth={0} href="/" icon={<IconSystem icon="home" variant="filled" title="Home Icon" />}>
								Startseite
							</NavigationListLinkItem>
							{rubrikenNavigationItems}
						</NavigationList>
					</Tab>
					<Tab label="Bayern" icon={<IconSystem icon="location" variant="filled" />}>
						<NavigationList>{bayernNavigationItems}</NavigationList>
					</Tab>
					<Tab label="Service" icon={<IconSystem icon="service" variant="filled" />}>
						<NavigationList>
							<NavigationListLinkItem depth={0} href="/bayern-wetter">
								Wetter
							</NavigationListLinkItem>
							<NavigationListLinkItem depth={0} href="/verkehrsmeldungen">
								Verkehr
							</NavigationListLinkItem>
							<NavigationListLinkItem depth={0} href="/meldung">
								Meldungen
							</NavigationListLinkItem>
							<NavigationListLinkItem depth={0} href="/schlagzeilen">
								Schlagzeilen
							</NavigationListLinkItem>
							<NavigationListLinkItem
								depth={0}
								href={radioUrl}
								target="_blank"
								icon={<IconSystem icon="external-link" variant="outline" title="Externer Link" />}
							>
								Radio
							</NavigationListLinkItem>
							<NavigationListCustomItem depth={0} className={styles.themeSelectorListItem}>
								<span className={`${styles.themeSelectorCaption} heading4`}>Farbschema</span>
								<ThemeSelectorMenu forceTheme="dark" />
							</NavigationListCustomItem>
						</NavigationList>
					</Tab>
				</Tabs>
			</nav>
		);
	})
);
