import { UsercentricsDialogToggle } from '@s-group/react-usercentrics';

import { Link } from '@/components/Link/Link';
import { NavigationTreeLeaf } from '@/cutils/page-context/navigation';
import { IconSystem } from 'src/client/icons/IconSystem';

import styles from './BRLinks.module.scss';
import { brLinks, impressumLinks } from './Footer.utils';

function isUsercentricsNode(
	node: NavigationTreeLeaf | { id: string; title: string; type: 'usercentrics' }
): node is { id: string; title: string; type: 'usercentrics' } {
	return (node as any)['type'] === 'usercentrics';
}

type Props = { className?: string };

export function BRLinks({ className }: Props) {
	return (
		<section className={`${styles.section} ${className ?? ''}`}>
			<div className={styles.brLinks}>
				{brLinks.map(({ url, icon, title }, index) => {
					return (
						<Link className={styles.iconLink} href={url} target="_blank" key={index} aria-label={title}>
							<IconSystem icon={icon} variant="filled" size="xl" title={title} />
						</Link>
					);
				})}
			</div>
			<div className={styles.impressum}>
				{impressumLinks.map((node) => {
					if (isUsercentricsNode(node)) {
						return (
							<UsercentricsDialogToggle className={styles.link} key={node.id}>
								{node.title} <IconSystem className={styles.icon} icon="next" variant="filled" aria-hidden={true} />
							</UsercentricsDialogToggle>
						);
					} else {
						const { link, isExternal, id, title } = node;
						return (
							<Link className={styles.link} href={link} target={isExternal ? '_blank' : undefined} key={id}>
								{title} <IconSystem className={styles.icon} icon="next" variant="filled" aria-hidden={true} />
							</Link>
						);
					}
				})}
			</div>
		</section>
	);
}
