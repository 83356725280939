import { ApolloClient, ApolloProvider } from '@apollo/client';
import { UsercentricsProvider } from '@s-group/react-usercentrics';
import { ThemeProvider } from 'next-themes';
import { ReactNode } from 'react';

import { useFeatureFlags } from '@/components/FeatureFlagsHook';

import { BreakpointMediaContextProvider } from '../breakpoints/media';
import { ServerSidePropsQuery } from '../page-context/__generated__/server-side-props.generated';

import AbcTestContext from './AbcTestContext';
import { Environment, EnvironmentProvider } from './EnvironmentContext';
import { MediaContextProvider } from './MediaContext';
import { TrackingProvider } from './TrackingProvider';

type Props = {
	children: ReactNode;
	featureFlagData: ServerSidePropsQuery['featureFlags'];
	apolloClient: ApolloClient<unknown>;
	environment: Environment;
};

const ContextProvider = ({ children, featureFlagData, apolloClient, environment }: Props) => {
	const { flags } = useFeatureFlags(featureFlagData);

	const variant = flags.abcTest ? flags.abcTest.variant : 'B';

	return (
		<EnvironmentProvider environment={environment}>
			<ApolloProvider client={apolloClient}>
				<AbcTestContext.Provider value={variant}>
					<BreakpointMediaContextProvider>
						<TrackingProvider featureFlagData={featureFlagData}>
							<MediaContextProvider>
								<ThemeProvider disableTransitionOnChange={true}>
									<UsercentricsProvider windowEventName="ucEvent">{children}</UsercentricsProvider>
								</ThemeProvider>
							</MediaContextProvider>
						</TrackingProvider>
					</BreakpointMediaContextProvider>
				</AbcTestContext.Provider>
			</ApolloProvider>
		</EnvironmentProvider>
	);
};

export default ContextProvider;
