import { Link } from '@/components/Link/Link';
import { NavigationList } from '@/cutils/page-context/navigation';

import styles from './LinkSection.module.scss';
import { Section } from './Section';

type Props = {
	title: string;
	links: NavigationList;
	className?: string;
	expandable?: boolean;
};

export function LinkSection({ title, links, className, expandable = true }: Props) {
	return (
		<Section title={title} className={className} expandable={expandable}>
			{links.map(({ title, id, link, isExternal }) => {
				return (
					<Link className={styles.link} href={link} target={isExternal ? '_blank' : undefined} key={id}>
						{title}
					</Link>
				);
			})}
		</Section>
	);
}
